export const navLinks = [
  {
    id: "about",
    title: "About",
    path: "/about",
  },
  {
    id: "products",
    title: "Products",
    path: "/#products",
  },
  {
    id: "blog",
    title: "Blog",
    path: "https://syxlabs.wordpress.com/",
    
  },
  {
    id: "careers",
    title: "Careers",
    path: "/careers",
  },
];

export const servs = [
  {
    title: "Blockchain",
    content:
      "Syx Labs is a team of blockchain technology enthusiasts who strive to create innovative and cutting-edge solutions. Our experts provide customized blockchain development services, including smart contract development, dApp development, blockchain consulting, and integration. We work with various blockchain technologies to create solutions that offer enhanced security, transparency, and trust for businesses. Our blockchain solutions are designed to streamline your business processes, reduce costs, and improve efficiency. Whether you want to build a blockchain-based platform from scratch or integrate blockchain technology into your existing systems, we've got you covered. Let us help you unlock the potential of blockchain technology and take your business to the next level.",
  },
  {
    title: "Data Science",
    content:
      "Syx Labs is a team of blockchain technology enthusiasts who strive to create innovative and cutting-edge solutions. Our experts provide customized blockchain development services, including smart contract development, dApp development, blockchain consulting, and integration. We work with various blockchain technologies to create solutions that offer enhanced security, transparency, and trust for businesses. Our blockchain solutions are designed to streamline your business processes, reduce costs, and improve efficiency. Whether you want to build a blockchain-based platform from scratch or integrate blockchain technology into your existing systems, we've got you covered. Let us help you unlock the potential of blockchain technology and take your business to the next level.",
  },
  {
    title: "Software Development",
    content:
      "Syx Labs is a team of blockchain technology enthusiasts who strive to create innovative and cutting-edge solutions. Our experts provide customized blockchain development services, including smart contract development, dApp development, blockchain consulting, and integration. We work with various blockchain technologies to create solutions that offer enhanced security, transparency, and trust for businesses. Our blockchain solutions are designed to streamline your business processes, reduce costs, and improve efficiency. Whether you want to build a blockchain-based platform from scratch or integrate blockchain technology into your existing systems, we've got you covered. Let us help you unlock the potential of blockchain technology and take your business to the next level.",
  },
  {
    title: "Artificial Intelligence",
    content:
      "Syx Labs is a team of blockchain technology enthusiasts who strive to create innovative and cutting-edge solutions. Our experts provide customized blockchain development services, including smart contract development, dApp development, blockchain consulting, and integration. We work with various blockchain technologies to create solutions that offer enhanced security, transparency, and trust for businesses. Our blockchain solutions are designed to streamline your business processes, reduce costs, and improve efficiency. Whether you want to build a blockchain-based platform from scratch or integrate blockchain technology into your existing systems, we've got you covered. Let us help you unlock the potential of blockchain technology and take your business to the next level.",
  },
  {
    title: "Consulting",
    content:
      "Syx Labs is a team of blockchain technology enthusiasts who strive to create innovative and cutting-edge solutions. Our experts provide customized blockchain development services, including smart contract development, dApp development, blockchain consulting, and integration. We work with various blockchain technologies to create solutions that offer enhanced security, transparency, and trust for businesses. Our blockchain solutions are designed to streamline your business processes, reduce costs, and improve efficiency. Whether you want to build a blockchain-based platform from scratch or integrate blockchain technology into your existing systems, we've got you covered. Let us help you unlock the potential of blockchain technology and take your business to the next level.",
  },
];

export const initialTabs = [
  "Blockchain",
  "Data Science",
  "Software Development",
  "Artificial Intelligence",
  "Consulting",
];
