import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import { styles } from "../styles";
import { navLinks } from "../constants";
import menu from "../assets/menu.png";
import arrow from "../assets/greenarrow.svg";
import close from "../assets/close.png";
import syxlogo from "../assets/syxlogo.svg";
import { Link, useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

import Twitter from "../assets/twitter.svg";
import Linkedin from "../assets/linkedIn.svg";
// import Tiktok from "../assets/tiktok.svg";
import Instagram from "../assets/instagram.svg";
import BackIcon from "../assets/back.svg";

import ChevronRight from "../assets/chevronRight.svg";

const services = [
  {
    id: 1,
    title: "Blockchain",
    path: "/blockchain",
  },
  {
    id: 2,
    title: "Artificial Intelligence",
    path: "/ai",
  },
  {
    id: 3,
    title: "Data Science",
    path: "/datascience",
  },
  {
    id: 4,
    title: "Software Development",
    path: "/softwaredevelopment",
  },

  {
    id: 4,
    title: "Consulting",
    path: "/consulting",
  },
];

const Header = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  const controls = useAnimation();

  const variants = {
    hidden: {
      x: "100%",
      transition: {
        duration: 0.5, // Adjust the duration as desired (in seconds)
      },
    },
    visible: {
      x: "0%",
      transition: {
        duration: 0.5, // Adjust the duration as desired (in seconds)
      },
    },
  };

  const handleToggle = async () => {
    await controls.start(toggle ? "hidden" : "visible");
    setToggle(!toggle);
  };

  useEffect(() => {
    if (location.pathname === "/industry") {
      const targetElement = document.getElementById("industry");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [location.pathname]);

  const handleNavLinkClick = (navLink) => {
    setActive(navLink.title);
    setToggle(false);

    if (navLink.title === "Industries") {
      if (location.pathname !== "/" && location.pathname !== "") {
        window.location.href = "/industry";
      } else {
        const targetElement = document.getElementById("industry");
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }
    }
  };

  const handleNavClick = (navLink) => {
    setActive(navLink.title);
    setToggle(false);

    if (navLink.title === "Blog") {
      // window.open("https://syxlabs.wordpress.com/", "_blank");
    } else {
      if (location.pathname !== "/" && location.pathname !== "") {
        // Handle other navigation links here
      } else {
        const targetElement = document.getElementById(navLink.id);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }
    }
  };

  const [hoverStates, setHoverStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleHover = (index, hoverState) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = hoverState;
      return newStates;
    });
  };

  return (
    <nav
      className={`
     ${styles.paddingX} w-full flex items-center fixed top-0 z-20 h-20 bg-white border-b-[#D9D9D9] border-2 `}
    >
      <div className="w-full flex justify-between items-center py-5 sm:py-0 mx-auto">
        <div className="flex items-center">
          <Link to="/">
            <img src={syxlogo} alt="logo" className="object-contain " />
          </Link>
          <ul className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 list-none hidden md:flex flex-row gap-10">
            {navLinks.map((navLink) => (
              <div
                key={v4()}
                className={`${
                  (active === navLink.title) === "Services"
                    ? "border-[#A6F200]"
                    : "text-secondary"
                } font-anuphan font-normal border-t-4 border-transparent cursor-pointer text-sm text-[#000000] py-5`}
                onClick={() => {
                  setToggle(!toggle);
                  handleNavLinkClick(navLink);
                  handleNavClick(navLink);
                  setActive(navLink.title);
                }}
                onMouseEnter={() => setActive(navLink.title)}
                // onMouseLeave={() => setActive("")}
              >
                {navLink.title === "Industries" ? (
                  <Link to={navLink.path} key={v4()}>
                    {navLink.title}
                  </Link>
                ):navLink.title === "Blog"?(<a href={navLink.path} target="_blank"> {navLink.title}</a>) : (
                  <Link to={navLink.path} key={v4()}>
                    {navLink.title}
                  </Link>
                )}

                {false && navLink.title === "Services" && (
                  <ul
                    className={`${
                      active === navLink.title ? "block" : "hidden"
                    } absolute left-0 top-full mt- bg-white w-full rounded shadow-lg`}
                    onMouseEnter={() => setActive(navLink.title)}
                    onMouseLeave={() => setActive("")}
                  >
                    <div className="flex flex-row text-black justify-evenly border-t-2 border-gray-300">
                      {/*blockchain */}
                      <div
                        className="hover:bg-[#003249] hover:text-white w-full pb-10 px-10 relative"
                        onMouseEnter={() => handleHover(0, true)}
                        onMouseLeave={() => handleHover(0, false)}
                      >
                        <div className="flex px-2 flex-col pl-3 pb-16">
                          <li>
                            <a
                              href="/blockchain"
                              className="block py-2  focus:outline-none font-medium text-sm"
                            >
                              Blockchain
                            </a>
                          </li>
                          <div className="flex flex-col mt-3 space-y-1 ">
                            <li>
                              <a
                                href="/blockchain"
                                className="block  py-2  focus:outline-none font-light text-xs"
                              >
                                Decenetralised Finance (DeFi) & <br></br> DApp
                                Development
                              </a>
                            </li>
                            <li>
                              <a
                                href="/blockchain"
                                className="block py-2 focus:outline-none font-light text-xs"
                              >
                                Smart Contract Development & <br></br> Auditing
                              </a>
                            </li>
                            <li>
                              <a
                                href="/blockchain"
                                className="block py-2 focus:outline-none font-light text-xs"
                              >
                                Non-Fungible Tokenization
                              </a>
                            </li>
                            <li>
                              <a
                                href="/blockchain"
                                className="block py-2 focus:outline-none font-light text-xs"
                              >
                                Cryptocurrency Exchange <br></br> Development
                              </a>
                            </li>
                            <li>
                              <a
                                href="/blockchain"
                                className="block pt-2 focus:outline-none font-light text-xs"
                              >
                                Blockchain Security
                              </a>
                            </li>
                          </div>
                        </div>
                        {hoverStates[0] && (
                          <div className="absolute left-[3.3rem] bottom-12  transform -translate-y-1/2 pointer-events-none">
                            <img src={arrow} alt="" className="w-5 h-5" />
                          </div>
                        )}
                      </div>

                      {/* ai */}
                      <div
                        className="flex flex-col px-2 border-l-2 pb-16 pl-3 border-gray-300 hover:bg-[#003249] hover:text-white w-full"
                        onMouseEnter={() => handleHover(1, true)}
                        onMouseLeave={() => handleHover(1, false)}
                      >
                        <li>
                          <a
                            href="#service1"
                            className="block py-2 focus:outline-none font-medium text-sm"
                          >
                            AI
                          </a>
                        </li>
                        <div className="flex flex-col mt-3 space-y-1 ">
                          <li>
                            <a
                              href="/ai"
                              className="block py-2  focus:outline-none font-light text-xs"
                            >
                              Computer vision and image recognition
                            </a>
                          </li>
                          <li>
                            <a
                              href="/ai"
                              className="block py-2 focus:outline-none font-light text-xs"
                            >
                              Voice assistants and chatbots
                            </a>
                          </li>
                          <li>
                            <a
                              href="/ai"
                              className="block py-2 focus:outline-none font-light text-xs"
                            >
                              Recommendation engines
                            </a>
                          </li>
                          <li>
                            <a
                              href="/ai"
                              className="block py-2 focus:outline-none font-light text-xs"
                            >
                              Autonomous vehicles and robotics
                            </a>
                          </li>
                          <li>
                            <a
                              href="/ai"
                              className="block py-2 focus:outline-none font-light text-xs"
                            >
                              Predictive maintenance and asset <br></br>{" "}
                              management
                            </a>
                          </li>
                        </div>
                        {hoverStates[1] && (
                          <div className="relative mt-8 transform -translate-y-1/2 pointer-events-none">
                            <img src={arrow} alt="" className="w-5 h-5" />
                          </div>
                        )}
                      </div>

                      {/*data science */}
                      <div
                        className="flex flex-col px-2 border-l-2 pb-16 pl-3 border-gray-300 hover:text-white hover:bg-[#003249] w-full"
                        onMouseEnter={() => handleHover(2, true)}
                        onMouseLeave={() => handleHover(2, false)}
                      >
                        <li>
                          <a
                            href="/datascience"
                            className="block py-2 focus:outline-none font-medium text-sm"
                          >
                            Data Science
                          </a>
                        </li>
                        <div className="flex flex-col mt-3 space-y-1 ">
                          <li>
                            <a
                              href="/datascience"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Predictive analysis & forecasting
                            </a>
                          </li>
                          <li>
                            <a
                              href="/datascience"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Machine Learning
                            </a>
                          </li>
                          <li>
                            <a
                              href="/datascience"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Natural Language Processing (NLP)
                            </a>
                          </li>
                          <li>
                            <a
                              href="/datascience"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Fraud detection and prevention
                            </a>
                          </li>
                          <li>
                            <a
                              href="/datascience"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Marketing analytics
                            </a>
                          </li>
                        </div>
                        {hoverStates[2] && (
                          <div className="relative mt-8 transform -translate-y-1/2 pointer-events-none">
                            <img src={arrow} alt="" className="w-5 h-5" />
                          </div>
                        )}
                      </div>

                      {/*software dev */}
                      <div
                        className="flex flex-col px-2 border-l-2 pb-16 pl-3 border-gray-300 hover:bg-[#003249] hover:text-white w-full"
                        onMouseEnter={() => handleHover(3, true)}
                        onMouseLeave={() => handleHover(3, false)}
                      >
                        <li>
                          <a
                            href="/softwaredevelopment"
                            className="block  py-2 focus:outline-none font-medium text-sm"
                          >
                            Software Development
                          </a>
                        </li>
                        <div className="flex flex-col mt-3 space-y-1 ">
                          <li>
                            <a
                              href="/softwaredevelopment"
                              className="block  py-2  focus:outline-none font-light text-xs"
                            >
                              Web application development
                            </a>
                          </li>
                          <li>
                            <a
                              href="/softwaredevelopment"
                              className="block  py-2  focus:outline-none font-light text-xs"
                            >
                              Mobile application development
                            </a>
                          </li>
                          <li>
                            <a
                              href="/softwaredevelopment"
                              className="block  py-2  focus:outline-none font-light text-xs"
                            >
                              Cloud computing and infrastructure
                            </a>
                          </li>
                          <li>
                            <a
                              href="/softwaredevelopment"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              DevOps and automation
                            </a>
                          </li>
                          <li>
                            <a
                              href="/softwaredevelopment"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Enterprise software development
                            </a>
                          </li>
                        </div>
                        {hoverStates[3] && (
                          <div className="relative mt-8 transform -translate-y-1/2 pointer-events-none">
                            <img src={arrow} alt="" className="w-5 h-5" />
                          </div>
                        )}
                      </div>

                      {/*consulting */}
                      <div
                        className="flex px-2  border-l-2 pl-3 pb-16 border-gray-300 flex-col hover:bg-[#003249] hover:text-white w-full"
                        onMouseEnter={() => handleHover(4, true)}
                        onMouseLeave={() => handleHover(4, false)}
                      >
                        <li>
                          <a
                            href="/consulting"
                            className="block  py-2 focus:outline-none font-medium text-sm"
                          >
                            Consulting
                          </a>
                        </li>
                        <div className="flex flex-col mt-3 space-y-1 ">
                          <li>
                            <a
                              href="/consulting"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Business strategy and transformation
                            </a>
                          </li>
                          <li>
                            <a
                              href="/consulting"
                              className="block  py-2  focus:outline-none font-light text-xs"
                            >
                              Organizational change management
                            </a>
                          </li>
                          <li>
                            <a
                              href="/consulting"
                              className="block  py-2  focus:outline-none font-light text-xs"
                            >
                              IT governance and risk management
                            </a>
                          </li>
                          <li>
                            <a
                              href="/consulting"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Process improvement and optimization
                            </a>
                          </li>
                          <li>
                            <a
                              href="/consulting"
                              className="block  py-2 focus:outline-none font-light text-xs"
                            >
                              Customer experience and engagement
                            </a>
                          </li>
                        </div>
                        {hoverStates[4] && (
                          <div className="relative mt-8 transform -translate-y-1/2 pointer-events-none">
                            <img src={arrow} alt="" className="w-5 h-5" />
                          </div>
                        )}
                      </div>
                    </div>
                  </ul>
                )}
              </div>
            ))}
          </ul>
        </div>

        {/*
        <Link to="/contact">
          <button className="flex  text-center items-center justify-center ml-20 text-primary text-sm bg-[#A6F200] h-[34px] py-[8px] px-[16px] hover:bg-black hover:text-white cursor-pointer">
            Contact Us
          </button>
        </Link>
				*/}
        <div className="md:hidden flex bg-white justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={() => setToggle(!toggle)}
          />
          <motion.div
            className={`${
              !toggle ? "hidden" : "flex flex-col justify-between "
            } top-0 w-screen h-screen bg-white left-0 right-0 absolute z-10`}
            initial="hidden"
            animate={toggle ? "visible" : "hidden"}
            variants={variants}
          >
            <div className="">
              <div className="control flex justify-between w-full mb-[16px] border-b border-[#D9D9D9] p-[16px]">
                <Link to="/">
                  <img src={syxlogo} alt="logo" className="object-contain " />
                </Link>

                <div className="">
                  <img
                    src={close}
                    alt="menu"
                    className="w-[28px] h-[28px] object-contain cursor-pointer"
                    onClick={() => handleToggle()}
                  />
                </div>
              </div>
              <ul className="list-none flex items-start flex-col gap-4 w-full ">
                {navLinks.map((Link) => (
                  <li
                    key={v4()}
                    className={`${
                      active === Link.title
                        ? "text-[#A6F200]"
                        : "text-secondary"
                    } hover:text-green-400 text-[base] flex items-center justify-between font-normal cursor-pointer border-b border-[#D9D9D9] w-full px-[16px] py-[10px] last:border-0 `}
                    onClick={() => setActive(Link.title)}
                  >
                    {Link.title === "Services" ? (
                      <p>{Link.title}</p>
                    ) : (
                      <a
                        href={`${Link.path}`}
                        onClick={() =>
                          Link.title === "Products" && setToggle(false)
                        }
                      >
                        {Link.title}
                      </a>
                    )}

                    <img
                      src={ChevronRight}
                      alt="arrow"
                      className=" object-contain cursor-pointer float-right"
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="socials  px-[16px] pb-[48px]">
              <h1 className="text-primary text-base font-normal mb-[16px]">
                Follow us here
              </h1>

              <div className="icons flex space-x-[16px]">
                {/* <img
                  src={Tiktok}
                  alt="facebook"
                  className="w-[28px] h-[28px] object-contain cursor-pointer"
                /> */}

                <a
                  href="https://www.instagram.com/syxlabs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Instagram} alt="" />
                </a>
                <a
                  href="https://twitter.com/syxlabs?s=21&t=XkKOZCOBbgAss1G5PzYuYg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/syx-labs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Linkedin} alt="" />
                </a>
              </div>
            </div>

            <>
              {active === "Services" && (
                <motion.div
                  className={`${
                    !toggle ? "hidden" : "flex flex-col justify-between "
                  } top-0 w-screen h-screen bg-white left-0 right-0 absolute z-10`}
                  initial="hidden"
                  animate={toggle ? "visible" : "hidden"}
                  variants={variants}
                >
                  <div className="">
                    <div className="control flex justify-between w-full border-b border-[#D9D9D9] p-[16px]">
                      <Link to="/">
                        <img
                          src={syxlogo}
                          alt="logo"
                          className="object-contain "
                        />
                      </Link>

                      <div className="">
                        <img
                          src={close}
                          alt="menu"
                          className="w-[28px] h-[28px] object-contain cursor-pointer"
                          // onClick={() => handleToggle()}

                          onClick={() => setActive("")}
                        />
                      </div>
                    </div>
                    <div
                      className="back bg-[#C5D5EA] py-[6px] px-[16px] flex space-x-[8px] mb-[16px]"
                      onClick={() => setActive("")}
                    >
                      <img
                        src={BackIcon}
                        alt="logo"
                        className="object-contain "
                      />
                      <h3 className="text-primary text-sm font-medium cursor-pointer">
                        Back
                      </h3>
                    </div>

                    <h1 className="text-primary text-[20px] font-medium mb-[16px] px-[16px]">
                      Services
                    </h1>
                    <ul className="list-none flex items-start flex-col gap-4 w-full ">
                      {services.map((Link) => (
                        <a
                          href={`${Link.path}`}
                          key={v4()}
                          className="flex items-center justify-between font-normal cursor-pointer border-b border-[#D9D9D9] w-full px-[16px] py-[10px] last:border-0"
                        >
                          <li
                            className={`${
                              active === Link.title
                                ? "text-[#A6F200]"
                                : "text-secondary"
                            } hover:text-green-400 text-[base]  `}
                            onClick={() => setActive(Link.title)}
                          >
                            {Link.title}
                          </li>

                          <img
                            src={ChevronRight}
                            alt="arrow"
                            className=" object-contain cursor-pointer float-right"
                          />
                        </a>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              )}
            </>
          </motion.div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
